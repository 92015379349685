import React from 'react'

import Layout from '@components/layout/Layout'
import SiteMapComponent from '@components/site-map/site-map.component'


const SiteMapPage = ({ location }) => {

  return (
    <Layout location={location}>
      <SiteMapComponent />
    </Layout>
  )
}

export default SiteMapPage
